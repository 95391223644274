/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getNewA = /* GraphQL */ `
  query GetNewA($id: ID!) {
    getNewA(id: $id) {
      id
      name
      surname
      idNumber
      email
      phone
      whatsapp
      country
      description
      busName
      package
      industry
      domain
      updatedAt
      createdAt
    }
  }
`;
export const listNewAS = /* GraphQL */ `
  query ListNewAS(
    $filter: ModelNewAFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNewAS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        idNumber
        email
        phone
        whatsapp
        country
        description
        busName
        package
        industry
        domain
        updatedAt
        createdAt
      }
      nextToken
    }
  }
`;
export const getTodo = /* GraphQL */ `
  query GetTodo($id: ID!) {
    getTodo(id: $id) {
      id
      name
      surname
      idNumber
      email
      phone
      whatsapp
      country
      description
      busName
      domaina
      domainb
      domainc
      createdAt
      updatedAt
    }
  }
`;
export const listTodos = /* GraphQL */ `
  query ListTodos(
    $filter: ModelTodoFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTodos(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        surname
        idNumber
        email
        phone
        whatsapp
        country
        description
        busName
        domaina
        domainb
        domainc
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
