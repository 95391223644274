/* src/App.js */
import React, { useEffect, useState } from 'react'
import Amplify, { API, graphqlOperation } from 'aws-amplify'
import { createTodo } from './graphql/mutations'
import { listTodos, listNewAS } from './graphql/queries'

import awsExports from "./aws-exports";
import logo from './assets/images/logo.png';
Amplify.configure(awsExports);


const initialState = { name: '', description: '', surname:'', email:'', idNumber:'', phone:'', whatsapp:'', country:'', busName:'', domaina: '', domainb:'', domainc:''   }

const App = () => {
  const [formState, setFormState] = useState(initialState)
  const [todos, setTodos] = useState([])

  useEffect(() => {
    fetchTodos()
  }, [])

  function setInput(key, value) {
    setFormState({ ...formState, [key]: value })
  }

  async function fetchTodos() {
    try {
      const todoData = await API.graphql(graphqlOperation(listNewAS))
      const todos = todoData.data.listNewAS.items
      setTodos(todos)
    } catch (err) { console.log('error fetching todos') }
  }



  async function addTodo() {
    try {
      if (!formState.name || !formState.description) return
      const todo = { ...formState }
      setTodos([...todos, todo])
      setFormState(initialState)
      await API.graphql(graphqlOperation(createTodo, {input: todo}))
    } catch (err) {
      console.log('error creating todo:', err)
    }
  }

  return (

    <div style={styles.mastercontainer}>
      <div style={styles.container}>
      <img src={logo} alt="Logo" style={styles.logo} />
      <h2>Applications</h2>
      <h3>New applications for hosting, contact user</h3>
 
    <div style={{backgroundColor: 'white', margin: 30, }}>
      {
        todos.map((todo, index) => (
          <div key={todo.id ? todo.id : index} style={styles.todo}>
            <p style={styles.todoDescription}>Title : {todo.description}</p>
            <p style={styles.todoName}>Name: {todo.name}</p>
            <p style={styles.todoDescription}> Surname: {todo.surname}</p>
            <p style={styles.todoDescription}>ID: {todo.idNumber}</p>
             <p style={styles.todoDescription}>E-Mail: {todo.email}</p>
            <p style={styles.todoDescription}>Phone: {todo.phone}</p>
            <p style={styles.todoDescription}>Whatsapp: {todo.whatsapp}</p>
            <p style={styles.todoDescription}>Country: {todo.country}</p>
            <p style={styles.todoDescription}>Bus Name: {todo.busName}</p>
            <p style={styles.todoDescription}>Package: {todo.package}</p>
            <p style={styles.todoDescription}>Industry: {todo.industry}</p>
            <p style={styles.todoDescription}>Domain: {todo.domain}</p>
            <p style={styles.todoDescription}>{todo.createdAt}</p>

            {/* <p style={styles.todoDescription}>{todo.id}</p> */}
            <button style={{width: 100, height: 50, backgroundColor: 'green', borderRadius: 10, alignItems: 'center', justifyContent: 'space-evenly'}}
            >
              <p>Remove</p>
            </button>
          </div>
        ))
      }
    </div>

      
    </div>
  
    </div>


    
  )
}

const styles = {
  mastercontainer:{ backgroundColor: 'white', marginLeft: 10,},
  container: { width: 300, backgroundColor: 'white', 
  display: 'flex', flexDirection: 'column', 
  justifyContent: 'center', padding: 20 

},
  todo: {  marginBottom: 15, marginTop: 50, borderRadius: 5, borderWidth: 5 },
  logo:{width: 200, height: 50},
  input: { borderWidth: 1, backgroundColor: 'white' ,borderRadius: 20, marginBottom: 10, padding: 8, fontSize: 15 },
  todoName: { fontSize: 20, fontWeight: 'bold' },
  todoDescription: { marginBottom: 0 },
  button: { backgroundColor: 'red', color: 'white', outline: 'none', fontSize: 18, padding: 8, borderRadius: 20 }
}

export default App